import { transform } from 'typescript';
import { IMachine, TimeSeriesObject, TimeSeriesStringObject } from '../api/domain-model';
import { ILineChartData, ILineChartData4Multiline, TimePeriod } from '../d3-charts/chart-domains';
import {
	IAreaChartOptions,
	IChartValue,
	StackedAreaChartType,
	IGaugeChartOptions as IDomainModelGaugeChartOptions,
	IColumnChartOptions,
	IColumnChartOptionsWithModel
} from '../charts/domain-model';
import { IStackChartOptions, IThermometerContentModel, IThermometerOptions, IGaugeChartOptions as IChartDomainGaugeChartOptions } from 'app/d3-charts/chart-domains';
import { Subject } from 'rxjs';

interface IStepValueHolder {
	stepValue: number;
}

interface INumberValueStringValueHolder {
	[key: string]: number;
}

export default class Utils {
	/**
	 *
	 * @param identifier eg./0030565002C0/sDynValue3
	 * @private checks whether the identifier contains 'sDynValue'
	 */
	public static isIdentifierStringVariable(identifier: string) {
		const regex = /\/[A-Za-z0-9-]+\/sDynValue\d/i;
		return (
			regex.test(identifier) ||
			// not a good way, but for TYP-2433 & TYP-2447
			this.variableStartsWithSButNotWithS32(identifier)
		);
	}

	private static variableStartsWithSButNotWithS32(identifier: string) {
		let variable = this.extractVariableFromIdentifier(identifier);
		return variable?.startsWith('s') && !variable?.startsWith('s32');
	}

	/**
	 *
	 * @param identifier eg. /003056500472/sCurrentType
	 * returns sCurrentType
	 */
	private static extractVariableFromIdentifier(identifier: string) {
		const parts = identifier?.split('/');
		const slashCount = parts?.length - 1;

		if (slashCount !== 2) {
			throw new Error('Not a valid identifier: ' + identifier);
		}

		return parts[2];
	}

	/**
	 * Adds arbitrary number value as the array contains only string values.
	 * Every change in the string value adds a new number value to the array.
	 * @param timeSeriesStringObjects
	 */
	public static populateValueFromString(timeSeriesStringObjects: Array<Array<TimeSeriesStringObject>>): Array<Array<TimeSeriesStringObject>> {
		// Check if every element in each sub-array of `timeSeriesStringObjects` has a `value` that can be parsed as a number
		// important for ticket TYP-2579 & 2580
		if(timeSeriesStringObjects.every(subArray => subArray.every(element => this.isParsableToNumber(element.value.toString()))) === true) {
			return timeSeriesStringObjects;
		}

		const stepValueHolder = { stepValue: 0 } as IStepValueHolder; // Passing by object to persist value once passed into function.
		const numberValueStringValueHolder = {} as INumberValueStringValueHolder;

		timeSeriesStringObjects.forEach((timeSeriesStringObjectArray) => {
			timeSeriesStringObjectArray.forEach((timeSeriesStringObject, index, wholeArray) => {
				this.populateStringValueInTimeSeriesObject(timeSeriesStringObject, numberValueStringValueHolder, stepValueHolder);
			});
		});
		return timeSeriesStringObjects;
	}

	public static createTimePeriod(timestampStart: number, timestampEnd: number): TimePeriod {
		return {
			timestampStart,
			timestampEnd
		} as TimePeriod;
	}

	public static mapDynamicTableUnitNumberToString(unitInt) {
		const textKey = [
			'A430kg',
			'A431lb',
			'A432t',
			'A433klb',
			'A434g',
			'A629Percent',
			'A124Minute',
			'A122Seconds',
			'A628UnitperH',
			'A642Hours',
			'A643Temp',
			'A644Millisecond',
			'A645KgPerSecond',
			'A646RoundsperMinute',
			'A647Meter',
			'A648Millimeter',
			'A649Cubicmeter',
			'A650Liter',
			'A651Counts',
			'A750GPerSecond'
		];
		return textKey[unitInt];
	}

	/**
	 *
	 * @param timeSeriesStringObject object in which the data has to populate
	 * @param numberValueStringValueHolder map stores string values and corresponding number values
	 * @param stepValueHolder holds the stepValue
	 * @private
	 * Populates a number value for every distinct string value.
	 */
	private static populateStringValueInTimeSeriesObject(
		timeSeriesStringObject: TimeSeriesStringObject,
		numberValueStringValueHolder: INumberValueStringValueHolder,
		stepValueHolder: IStepValueHolder
	) {
		const stringValue = timeSeriesStringObject.value.toString();

		let assignedValue = 0;
		if (numberValueStringValueHolder[stringValue]) {
			assignedValue = numberValueStringValueHolder[stringValue];
		} else {
			stepValueHolder.stepValue += 5;
			assignedValue = stepValueHolder.stepValue;
			numberValueStringValueHolder[stringValue] = assignedValue;
		}

		timeSeriesStringObject.stringValue = stringValue;
		timeSeriesStringObject.value = assignedValue;
	}

	public static isParsableToNumber(value: string): boolean {
		const num = parseFloat(value);
		return !isNaN(num);
	}

	public static isPrefixInNeededValues(neededValues: Array<Array<string>>, prefix: string): boolean {
		if (prefix === '' || !neededValues) {
			return false;
		}
		const flattenValues = neededValues.flat();
		if (flattenValues.length <= 0) {
			return false;
		}
		return flattenValues.some((value) => value.startsWith(prefix));
	}

	public static getMqttPrefixFromMachine(machine: IMachine): string {
		//return machine ? `${machine.companyId}/${machine.name}` : 'NOMACHINE';
		return machine ? `${machine.name}` : 'NOMACHINE';
	}

	// ! This is used to avoid Typescript errors in the templates
	// ! In future we should fix the castings
	// ! Because this "castTo<T>" is nothing more than an any cast
	private static castTo<T>(): (input) => T {
		return (input) => input as T;
	}
	private static transformGaugeChartOptions(input: IDomainModelGaugeChartOptions): IChartDomainGaugeChartOptions {
		return Utils.castTo<IChartDomainGaugeChartOptions>()(input);
	}
	private static transformColumnChartOptions(input: IColumnChartOptions): IColumnChartOptionsWithModel {
		return Utils.castTo<IColumnChartOptionsWithModel>()(input);
	}
	private static transformArrayInDoubleArray(input: Array<string>): Array<Array<string>> {
		// This is used by neededValues
		return Utils.castTo<Array<Array<string>>>()(input);
	}
	private static transformDoubleArrayInArray(input: Array<Array<string>>): Array<string> {
		return Utils.castTo<Array<string>>()(input);
	}
	private static transformString2Number(input: string): number {
		return input ? parseFloat(input) : null;
	}
	public static transformD3MultiLineTimeSeriesData(input: Subject<IChartValue[]>): ILineChartData[][] {
		return Utils.castTo<ILineChartData[][]>()(input);
	}
	public static transformD3MultiLine4MultilineTimeSeriesData(input: Subject<IChartValue[]>): ILineChartData4Multiline[] {
		return Utils.castTo<ILineChartData4Multiline[]>()(input);
	}
	public static transformMachineReport_SelectedHistoryItems(input: Record<string, unknown>[]): string[] {
		return Utils.castTo<string[]>()(input);
	}
	public static transformD3Multiline_TimeSeriesData(input: TimeSeriesObject[][]): Array<Array<ILineChartData>> {
		return Utils.castTo<Array<Array<ILineChartData>>>()(input);
	}
	public static transformD3Multiline4Multiline_TimeSeriesData(input: TimeSeriesObject[][]): Array<ILineChartData4Multiline> {
		return Utils.castTo<Array<ILineChartData4Multiline>>()(input);
	}
	public static Cast = {
		transformGaugeChartOptions: Utils.transformGaugeChartOptions,
		transformColumnChartOptions: Utils.transformColumnChartOptions,
		transformArrayInDoubleArray: Utils.transformArrayInDoubleArray,
		transformDoubleArrayInArray: Utils.transformDoubleArrayInArray,
		transformString2Number: Utils.transformString2Number,
		transformD3MultiLineTimeSeriesData: Utils.transformD3MultiLineTimeSeriesData,
		transformD3MultiLine4MultilineTimeSeriesData: Utils.transformD3MultiLine4MultilineTimeSeriesData,
		transformMachineReport_SelectedHistoryItems: Utils.transformMachineReport_SelectedHistoryItems,
		transformD3Multiline_TimeSeriesData: Utils.transformD3Multiline_TimeSeriesData,
		transformD3Multiline4Multiline_TimeSeriesData: Utils.transformD3Multiline4Multiline_TimeSeriesData
	};
}
