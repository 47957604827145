import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { CompanyDomainService } from './api/company-domain.service';
import { IMachine } from './api/domain-model';
import { EventEmitterService } from './api/event.service';
import { MqttService } from './api/mqtt.service';
import { AuthService } from './shared/auth-service';
import { Constants } from './shared/Constants';
import { CountryList, TranslateService } from './shared/translate';

@Component({
	selector: 'app-root',
	templateUrl: './root.component.html',
	styleUrls: ['root.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootComponent implements OnInit {
	counter = 0;

	public buildVersion: Array<string> = [];
	public isConfig = false;
	appVersion: any;
	rxsubscription: any;
	machine: IMachine;
	public theming = 1;
	public gitCommitTime: Date;
	public gitCommitId: string;
	lasttraffic: Date;
	public theme = 'H&B';
	@HostBinding('class') componentCssClass;
	propertiesJson: {
		'git.build.time': string;
		'git.commit.id.abbrev': string;
		'git.total.commit.count': string;
	};
	propertiesJsonStringify: string;

	constructor(
		public mqttService: MqttService,
		private swUpdate: SwUpdate,
		private eventEmitterService: EventEmitterService,
		private translateService: TranslateService,
		public overlayContainer: OverlayContainer,
		private cd: ChangeDetectorRef,
		private authService: AuthService,
		private companyDomainService: CompanyDomainService
	) {
		// read theme from url
		// This will affect the login screen theme. The theme will change to the users theme when he is logged in
		const urlParams = new URLSearchParams(window.location.search);
		if (window.location.hostname.startsWith('feige') || window.location.hostname.startsWith('www.feige') || urlParams.get('theme') === 'feige') {
			this.theme = 'Feige';
		} else if (window.location.hostname.startsWith('aventus') || window.location.hostname.startsWith('www.aventus') || urlParams.get('theme') === 'aventus') {
			this.theme = 'Aventus';
		} else if (window.location.hostname.startsWith('newtec') || window.location.hostname.startsWith('www.newtec') || urlParams.get('theme') === 'newtec') {
			this.theme = 'Newtec';
		} else if (window.location.hostname.startsWith('niagara') || window.location.hostname.startsWith('www.niagara') || urlParams.get('theme') === 'niagara') {
			this.theme = 'Niagara';
		} else if (window.location.hostname.startsWith('behnbates') || window.location.hostname.startsWith('www.behnbates') || urlParams.get('theme') === 'behnbates') {
			this.theme = 'Behnbates';
		}
		this.eventEmitterService.theme = this.theme;
		this.overlayContainer.getContainerElement().classList.add(this.transformToStyleNaming(this.theme));
		this.componentCssClass = this.transformToStyleNaming(this.theme);

		this.addTracking();
	}

	get lang() {
		return window.location.pathname.split('/')[2];
	}

	get isLoggedIn(): boolean {
		return this.authService.isLoggedIn;
	}

	ngOnInit() {
		// is this piece of code neccessary for something?
		//this.router.events.subscribe((event) => {
		//	if (event instanceof NavigationEnd) {
		//		console.log(event);
		//	}
		//});

		this.swUpdate.available.subscribe((event) => {
			window.confirm('There is an update available on this application. Will reload now with the new version.');
			window.location.reload();
		});

		let packageJson = require('../../package.json');
		this.propertiesJson = require('./git.properties.json');
		this.appVersion = packageJson.version + '.' + this.propertiesJson['git.total.commit.count'];
		this.propertiesJsonStringify = JSON.stringify(this.propertiesJson, null, 2);
		//console.log('version:', this.appVersion, this.propertiesJson);

		this.eventEmitterService.machineEmitter.subscribe((machine) => {
			if (this.machine) {
				this.mqttService.unsubscribeValue(this.machine, 'lasttraffic');
			}
			if (machine) {
				this.mqttService.subscribeValue(machine, 'lasttraffic');
			}
			this.machine = machine;
		});

		// just the subscription on the lasttraffic-values
		this.rxsubscription = this.mqttService
			.filterStream(
				() => (this.machine ? this.machine.companyId.toString() : 'NOMACHINE'),
				() => ['lasttraffic']
			)
			.subscribe((iotMessage) => {
				this.lasttraffic = new Date(+iotMessage.when);
				this.cd.markForCheck();
			});

		const routedCountry = CountryList.find((element) => {
			return element.key === this.lang;
		});

		if (routedCountry) {
			localStorage.setItem(Constants.COUNTRYKEY, routedCountry.key);
			localStorage.setItem(Constants.COUNTRYNAMEKEY, routedCountry.name);
			this.eventEmitterService.langtrigger.next(routedCountry.key);
			this.cd.markForCheck();
		} else {
			const countryKey = localStorage.getItem(Constants.COUNTRYKEY);
			const countryName = localStorage.getItem(Constants.COUNTRYNAMEKEY);

			localStorage.setItem(Constants.COUNTRYKEY, countryKey || 'en');
			localStorage.setItem(Constants.COUNTRYNAMEKEY, countryName || 'A003English');
			this.eventEmitterService.langtrigger.next(countryKey || 'en');
			this.cd.markForCheck();
		}

		// generate random session id for unique session token value
		localStorage.setItem('generatedSessionId', Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2));

		// change the theme according to the domain of the current user
		this.authService.currentUser.subscribe((cu) => {
			if (cu != null && cu.domain.name != this.theme) {
				this.changeTheme(cu.domain.name);
				this.changeURL(cu.domain.prefix);
				this.theme = cu.domain.name;
			}
		});
	}

	onImprintClicked() {
		if (this.translateService.currentLang === 'de') {
			window.open('https://www.haverboecker.com/de/impressum/', '_blank');
		} else {
			window.open('https://www.haverboecker.com/en/imprint', '_blank');
		}
	}

	changeTheme(newTheme) {
		this.eventEmitterService.themeEmitter.next(newTheme);
		this.overlayContainer.getContainerElement().classList.remove(this.transformToStyleNaming(this.theme));
		this.overlayContainer.getContainerElement().classList.add(this.transformToStyleNaming(newTheme));
		this.componentCssClass = this.transformToStyleNaming(newTheme);
	}

	transformToStyleNaming(theme) {
		if (theme == 'H&B') {
			return 'hb-boecker-theme';
		} else if (theme == 'Feige') {
			return 'hb-feige-theme';
		} else if (theme == 'Newtec') {
			return 'hb-newtec-theme';
		} else if (theme == 'Aventus') {
			return 'hb-aventus-theme';
		} else if (theme == 'Niagara') {
			return 'hb-niagara-theme';
		} else if (theme == 'Behnbates') {
			return 'hb-behnbates-theme';
		} else {
			return 'hb-boecker-theme';
		}
	}

	// function to change the url to the correct domain prefix
	// this prefix is now given by the backend in the whoami call of the User->Domain Object
	changeURL(urlThemePrefix) {
		if (window.location.hostname == 'localhost') {
			return;
		}

		let hostname = window.location.hostname;
		// remove the 'www.' part if it is there
		if (hostname.startsWith('www.')) {
			hostname = hostname.substring(4);
		}

		// Remove the previous domain prefix (if it is there)
		// i.e. feige-dev-trackyourplant -> dev-trackyourplant
		this.companyDomainService.getCompanyDomains().subscribe((domains) => {
			for (let domain in domains) {
				hostname = hostname.replace(domains[domain].prefix, '');
			}
			// now add the new hostname
			hostname = urlThemePrefix + hostname;

			if (this.authService.userSnapshot != null) {
				this.authService.logout();
			}

			// now replace the old hostname with the updated one
			window.location.href = window.location.href.replace(window.location.hostname, hostname);
		});
	}

	onPrivacyPolicyClicked() {
		if (this.translateService.currentLang === 'de') {
			window.open('https://www.haverboecker.com/de/datenschutz/', '_blank');
		} else {
			window.open('https://www.haverboecker.com/en/private-policity/', '_blank');
		}
	}

	addTracking() {
		const newScript = document.createElement('script');
		newScript.type = 'application/javascript';
		newScript.src = 'https://app.usercentrics.eu/latest/main.js';
		newScript.id = '5P2syQkIE';

		if (window.location.hostname.startsWith('testing') || window.location.hostname.startsWith('www.testing') || window.location.hostname.startsWith('localhost')) {
			newScript.id = 'kdspxYSlO';
		} else if (window.location.hostname.startsWith('qa-trackyourplant') || window.location.hostname.startsWith('www.qa-trackyourplant')) {
			newScript.id = 'iHv04vRp5';
		}

		const s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(newScript, s);
	}
}
